import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react';
import { trackSelectPromotion } from '../../../lib/ga4'
import Link from '../../Link/index'
import ButtonCTA from '../Button/button_cta'
import Video from '../Video'
import { debounce } from 'lodash'

const HeaderCopyHeading = styled.div`
  /* width: 558px;
  height: 76px; */
  object-fit: contain;
  ${(props) => (props.HeaderFontWeight ? `font-weight: ${props.HeaderFontWeight};` : 'font-weight: 500;')}
  ${(props) => (props.HeaderFont ? `font-family: ${props.HeaderFont};` : 'font-family: Objectivity;')}
  ${(props) => (props.HeaderFontSize ? `font-size: ${props.HeaderFontSize}px;` : 'font-size: 96px;')}
  ${(props) => (props.HeaderTextColor ? `color: ${props.HeaderTextColor};` : 'color: white;')}
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: -1.46px;
  font-style: ${(props) => (props.HeaderFont === 'PPEditorialNew' ? `italic;` : 'normal;')};

  @media (min-width: 769px) {
    ${(props) => (props.HeaderCopyPosition ? `position: absolute; top: 0; left: 0; padding: 5% 3%; font-size: 108px; font-weight: 300;` : '')};
  }
  @media (max-width: 768px) {
    ${(props) => (props.HeaderFontSize ? `font-size: calc(${props.HeaderFontSize / 2}px);` : 'font-size: 52px;')}
    text-align: center;
    ${(props) => (props.HeaderCopyPosition ? `position: absolute; top: 0; left: 0; padding: 40px 20px; font-size: 64px; font-weight: 300; text-align: left;` : '')};
  }

  @media (max-width: 768px) {
    margin: 0;
    line-height: 1.25;
    letter-spacing: 1;
    width: 90%;
    padding: 0 20px;
  }
`
const AboveTheHeader = styled.div`
  object-fit: contain;
  font-weight: 400;
  font-family: 'SackersGothicStd-Heavy';
  font-size: 24px;
  color: white;
  text-align: center;
  padding: 16px 0;
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 0;
  }
`
const HeaderCopySubHeading = styled.div`
  font-size: ${(props) => props.SubHeaderFontSize ? `${props.SubHeaderFontSize}px;` : '21px;'}
  ${(props) => props.BorderColor === '#FFAC42' ? `font-size: 170px` : ''};
  font-stretch: normal;
  line-height: ${(props) => props.LineHeight ? props.LineHeight : '1.39'};

  line-height: ${(props) => props.BorderColor === '#FFAC42' ? `1;` : ''};
  letter-spacing: -0.8px;
  text-align: center;
  ${(props) => (props.SubHeaderFontWeight ? `font-weight: ${props.SubHeaderFontWeight};` : 'font-weight: 500;')}
  ${(props) => (props.SubHeaderFont ? `font-family: ${props.SubHeaderFont};` : 'font-family: Objectivity;')}
  ${(props) => (props.SubHeaderFontStyle ? `font-style: ${props.SubHeaderFontStyle};` : 'font-style: normal;')}
  ${(props) => (props.SubHeaderTextColor ? `color: ${props.SubHeaderTextColor};` : 'color: white;')}
  & p {
    margin-bottom: 0px;
    padding: 8px 16px;
	}
  @media (min-width: 769px) {
    ${(props) => (props.SubHeaderPosition ? `position: absolute; bottom: 0; right: 0; padding: 8% 3%; font-size: 108px; font-weight: 300;` : '')};
  }

  @media (max-width : 1024px) {
    font-size: ${(props) => props.SubHeaderFontSize ? `calc(${props.SubHeaderFontSize}px / 1.5);` : '18px;'}
    font-size: ${(props) => props.BorderColor === '#FFAC42' ? `52px` : ''};
    letter-spacing: -0.16px;
    ${(props) => (props.SubHeaderPosition ? `position: absolute; bottom: 0; right: 0; padding: 120px 20px; font-size: 64px; font-weight: 300;` : '')};
  }
  @media (max-width: 768px) {
    opacity: 1;
    padding-top: 0;
    font-size: 14px;
    letter-spacing: -0.12px;
    text-align: center !important;
    line-height: 1;
  }
  & p {
    margin-bottom: 0px;
    padding: 8px 16px;
	}
`
const SectionContainerTop = styled.div`
  background-color: ${(props) => props.color};
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  @media (max-width: 768px) {
    ${(props) => (props.mobilePadding ? 'padding: 32px 0px;' : '')};
    flex-direction: ${(props) => (props.reverseMobile ? 'column-reverse' : 'column')};
    height: ${(props) => (props.TextPosition === 'end' ? 'calc(90vh - 48px);' : `calc(${props.HeightPercentage || 75}vh - 48px);`)};
  }
  @media (min-width: 769px) {
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
    ${(props) =>
    props.HeightPercentage
      ? `height: calc(${props.HeightPercentage}vh - 126px);`
      : 'height: calc(100vh - 126px);'}
    ${(props) => (props.full ? 'height: calc(100vh - 126px);' : '')}
    ${(props) => (props.negativeMargin ? 'height: calc(75vh - 126px);' : '')}
    ${(props) => (props.maxWidth ? 'max-width: 1200px; margin: 0 auto;' : '')}
  }
`
const CopyWidthContainer = styled.div`
  @media (min-width: 769px) {
    max-width: 1000px;
    margin: 0 auto;
    ${({ center }) => (center === false ? '' : 'margin: 0 auto;')}
  }
  ${(props) =>
    props.elements === true
      ? `
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    `
      : ''}
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
  }
`
const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  ${(props) => (props.background ? 'background: black;' : '')}

  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 768px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : '')}
  }

  img {
    opacity: 1 !important; /* Override the default opacity */
  }
`
const TitleImage = styled(GatsbyImage)`
  width: 800px;
  max-width: 100%;
  justify-content: center;
  display: flex;
  @media (max-width: 1025px) {
    height: ${(props) => (props.titleimagemobileheight ? `${props.titleimagemobileheight}px;` : 'auto;')};
    object-fit: contain;
  }
  @media (max-width: 768px) {
    height: ${(props) => (props.titleimagemobileheight ? `${props.titleimagemobileheight}px;` : 'auto;')};
    object-fit: contain;
  }
`
const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 768px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`
const HeaderVideo = styled(Video)`
  width: 50%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 768px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const SectionCopy = styled.div`
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  position: absolute;
  ${(props) => (props.backgroundColor ? `background: ${props.backgroundColor}` : '')}
  ${(props) => props.full && 'width: 100%;'};
  left: 0;
  right: 0;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(var(--${(props) => props.color}));
  top: 0;
  justify-content: center;
  justify-content: ${(props) => (props.to?.includes('personalisation') ? `start;` : ';')};
  padding-top: ${(props) => (props.TextPosition === 'start' ? `52px;` : '0;')};
  ${(props) => (props.TextPosition === 'end' ? `padding-bottom: 10%;` : '0;')};
  justify-content: ${(props) => (props.TextPosition ? `${props.TextPosition};` : ';')};

  @media (min-width: 769px) {
    ${({ trunk }) => trunk && `align-items: flex-end; text-align: center; padding-right: 10%;`}
    ${({ school }) => school && `align-items: flex-start; text-align: left; padding-left: 10%;`}
    ${(props) => (props.buttonPos === 'end' && `justify-content: ${props.buttonPos}; padding-bottom: 10vh`)};
  }
  @media (max-width: 1024px) {
    top: 0%;
    width: 100%;
    ${({ noMin }) => noMin && 'height : 100%;'}
    text-align: left;
    z-index: 2;
    ${(props) => (props.left ? 'align-items: flex-start;' : '')};
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) =>
    props.center ? 'text-align: center; align-items: center;' : ''}
    ${({ trunk }) => trunk && `align-items: center;text-align: center; `}
    ${(props) => (props.mobileAbsolute ? 'position: absolute; top: 22px; padding-top: 8px;' : '')}
    justify-content: ${(props) => (props.TextPositionMobile ? `${props.TextPositionMobile};` : 'center;')};
    padding-top: ${(props) => (props.TextPositionMobile === 'start' ? `32px;` : '0;')};
    ${(props) => (props.buttonPos && `justify-content: ${props.buttonPos};`)};
  }
  @media (max-width: 768px) {
    ${(props) => (props.TextPositionMobile === 'end' ? `padding-bottom: 5%;` : '0;')};
    ${(props) => (props.buttonPos && `justify-content: ${props.buttonPos}; padding-bottom: 5%`)};
    height: ${(props) => props.BorderColor === '#FFAC42' ? `80%;` : ''};
  }
  @media (max-width: 320px) {
    ${({ noMin }) => !noMin && 'height: 90%'}

  }
`
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 200);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const extractFilename = (path) => {
  const parts = path.split('/');
  return parts[parts.length - 1];
};

const FullBanner = React.memo(({ data }) => {
  const size = useWindowSize();

  return (
    <Link to={data.to} noLink={data.to === null}>
      <SectionContainerTop
        reverseMobile={true}
        onClick={data.onClick}
        HeightPercentage={data.HeightPercentage}
        TextPosition={data.TextPosition}
        TextPositionMobile={data.TextPositionMobile}
      >
        {data.DesktopImage?.isStatic ? (
          <StaticHeaderImage
            src={size.width > 1025 ? data.DesktopImage?.url : data.MobileImage?.url}
          />
        ) : data.DesktopImage?.mime?.includes('video') ? (
          size.width === 0 ? (
            <></>
          ) : (
            <HeaderVideo
              padding={data.ImagePadding}
              objectPosition={true}
              loading='eager'
              alt={
                size.width > 769
                  ? data.DesktopImage?.alternativeText ||
                    extractFilename(data.DesktopImage?.file?.publicURL)
                  : data.MobileImage?.alternativeText ||
                    extractFilename(data.MobileImage?.file?.publicURL)
              }
              src={
                size.width > 769
                  ? data.DesktopImage?.file?.publicURL
                  : data.MobileImage?.file?.publicURL
              }
            />
          )
        ) : (
          data.DesktopImage && (
            <HeaderImage
              objectFit='cover'
              alt={
                data.DesktopImage?.alternativeText !== null &&
                data.DesktopImage?.alternativeText !== undefined
                  ? data.DesktopImage?.alternativeText
                  : extractFilename(data.DesktopImage?.file?.publicURL)
              }
              image={withArtDirection(
                data.DesktopImage?.file?.childImageSharp?.gatsbyImageData,
                [
                  {
                    media: '(max-width: 1024px)',
                    image: data.MobileImage?.file?.childImageSharp?.gatsbyImageDataMobile,
                  },
                ]
              )}
            />
          )
        )}
        <SectionCopy
          noMin={data.centerV}
          center={!data.left}
          backgroundColour={data.Body && (data?.Heading || data?.CustomHeading) ? '' : 'black'}
          TextPosition={data.TextPosition}
          TextPositionMobile={data.TextPositionMobile}
          to={data.to}
          buttonPos={
            !data?.Heading && !data?.CustomHeading && !data.Body && !data?.TextPosition
              ? 'end'
              : ''
          }
        >
          <CopyWidthContainer style={{ textAlign: data.left ? 'left' : 'center' }}>
            {data?.aboveTheHeader && <AboveTheHeader>{data?.aboveTheHeader}</AboveTheHeader>}
            {data.TitleImage ? (
              <HeaderCopyHeading
                large={true}
                HeaderTextColor={data.HeaderTextColor}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <TitleImage
                  objectPosition='center'
                  objectFit='contain'
                  loading='eager'
                  titleimagemobileheight={data?.TitleImageMobileHeight}
                  alt={data.TitleImage?.alternativeText || extractFilename(data.TitleImage?.file?.publicURL)}
                  image={withArtDirection(
                    getImage(data.TitleImage?.file?.childImageSharp?.gatsbyImageData),
                    [
                      {
                        media: '(max-width: 1024px)',
                        image: getImage(
                          data?.TitleImageMobile
                            ? data.TitleImageMobile?.file?.childImageSharp?.gatsbyImageDataMobile
                            : data.TitleImage?.file?.childImageSharp?.gatsbyImageData
                        ),
                      },
                    ]
                  )}
                />
              </HeaderCopyHeading>
            ) : (
              data?.CustomHeading ? (
                <HeaderCopyHeading
                  large={true}
                  HeaderFontWeight={data.HeaderFontWeight}
                  HeaderFontSize={data.HeaderFontSize}
                  HeaderFont={data.HeaderFont}
                  HeaderTextColor={data.HeaderTextColor}
                  HeaderCopyPosition={data?.Button?.ButtonPosition}
                  HeaderFontStyle={data.HeaderFontStyle}
                  dangerouslySetInnerHTML={{ __html: data?.CustomHeading }}
                />
              ) : (
                <HeaderCopyHeading
                  large={true}
                  HeaderFontWeight={data.HeaderFontWeight}
                  HeaderFontSize={data.HeaderFontSize}
                  HeaderFont={data.HeaderFont}
                  HeaderTextColor={data.HeaderTextColor}
                  HeaderCopyPosition={data?.Button?.ButtonPosition}
                  HeaderFontStyle={data.HeaderFontStyle}
                >
                  {data?.Heading}
                </HeaderCopyHeading>
              )
            )}
            {data.Body && (
              <HeaderCopySubHeading
                BorderColor={data.Button?.BorderColor}
                SubHeaderFontWeight={data.SubHeaderFontWeight}
                SubHeaderFontSize={data.SubHeaderFontSize}
                SubHeaderFont={data.SubHeaderFont}
                SubHeaderFontStyle={data.SubHeaderFontStyle}
                SubHeaderTextColor={data.SubHeaderTextColor}
                LineHeight={data.LineHeight}
                SubHeaderPosition={data?.Button?.ButtonPosition}
                style={{ textAlign: data.left ? 'left' : 'center' }}
                dangerouslySetInnerHTML={{ __html: data.Body }}
              />
            )}
            {data.Button && (
              <ButtonCTA
                style={{ padding: '0 48px', margin: '12px' }}
                data={data.Button}
                noLink={data?.to ? true : false}
                onClick={() => {
                  trackSelectPromotion({ creative_name: data.Heading, promotion_id: data.id });
                }}
              >
                {data.Button.ButtonCopy}
              </ButtonCTA>
            )}
          </CopyWidthContainer>
        </SectionCopy>
      </SectionContainerTop>
    </Link>
  );
});

export default FullBanner;
